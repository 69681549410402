import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {path:'/',redirect:{name:"首页"}},
  {
    path: '/Home',
    name: '首页',
    component: resolve => require(['../views/Home.vue'],resolve),
  },
  {
    path: '/ServiceCenter',
    name: '服务中心',
    component: resolve => require(['../views/ServiceCenter.vue'],resolve),
  },
  {
    path: '/CaseShow',
    name: '项目案例',
    component: resolve => require(['../views/CaseShow.vue'],resolve),
  },
  {
    path: '/sixProductLines',
    name: '六大产品线',
    component: resolve => require(['../views/CaseShow/sixProductLines.vue'],resolve),
  },
  {
    path: '/jellyPlanet',
    name: '果冻星球',
    component: resolve => require(['../views/CaseShow/jellyPlanet.vue'],resolve),
  },
  {
    path: '/resources',
    name: '演艺资源',
    component: resolve => require(['../views/CaseShow/resources.vue'],resolve),
  },
  {
    path: '/OurProducts',
    name: '我们的产品',
    component: resolve => require(['../views/OurProducts.vue'],resolve),
  },
  {
    path: '/AboutUs',
    name: '关于我们',
    component: resolve => require(['../views/AboutUs.vue'],resolve),
  },
  {
    path: '/CooperationNegotiate',
    name: '合作洽谈',
    component: resolve => require(['../views/CooperationNegotiate.vue'],resolve),
  },
  {
    path: '/ServiceAgreement',
    name: '服务协议',
    component: resolve => require(['../views/ServiceAgreement.vue'],resolve),
  },
  {
    path: '/PrivacyPolicy',
    name: '隐私政策',
    component: resolve => require(['../views/PrivacyPolicy.vue'],resolve),
  },
  {
    path: '/LegalNotices',
    name: '法律声明',
    component: resolve => require(['../views/LegalNotices.vue'],resolve),
  },
  {
    path: '/RuleOfSunshine',
    name: '阳光准则',
    component: resolve => require(['../views/RuleOfSunshine.vue'],resolve),
  },
]

const router = new VueRouter({
  routes
})

export default router
