<template>
    <div class="sixProductLines">
        <!-- <h1>六大产品线</h1> -->
        <img src="https://img.youstarplanet.com/website/caseShow/CaseShow.png" alt="">
        <div class="navigation" @click="CaseShow"><span>案例展示 > 六大产品线</span></div>
        <div class="content">
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为更好地服务广大用户，进一步满足客户对优质演艺文化内容的需求，并提供一揽子落地服务，2021年YSP在整合大量优质资源的基础上，开发出了内容丰富、形式多样的演艺文化系列产品。</p>
            <el-carousel indicator-position="outside">
                <el-carousel-item v-for="item in content_img" :key="item.id">
                    <div><img :src="item" alt=""></div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return {
            content_img:[
                "https://img.youstarplanet.com/website/caseShow/sixProductLines1.png",
                "https://img.youstarplanet.com/website/caseShow/sixProductLines2.png",
                "https://img.youstarplanet.com/website/caseShow/sixProductLines3.png",
                "https://img.youstarplanet.com/website/caseShow/sixProductLines4.png",
                "https://img.youstarplanet.com/website/caseShow/sixProductLines5.png",
                "https://img.youstarplanet.com/website/caseShow/sixProductLines6.png",
            ], 
        }
    },
    methods: {
        CaseShow(){
            this.$router.push({path:`/CaseShow`})
        }
    },
    async mounted() {
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.sixProductLines{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .navigation{
        padding: 0 325px;
        height: 48px;
        line-height: 48px;
        background-color: #F1F1F1;
        color: #8A8A8A;
        font-size: 14px;
        position: relative;
        top: -4px;
        span{
            display: block;
            width: 1270px;
            margin: 0 auto;
        }
    }
    .content{
        width: 1270px;
        margin: 0 auto;
        margin-bottom: 200px;
        &>p{
            font-size: 16px;
            margin: 60px 0;
            padding: 0 80px;
        }
        .el-carousel__container{
            height: 785px;
        }
    }
}
</style>