<template>
    <div class="RuleOfSunshine">
        <!-- <h1>阳光准则</h1> -->
        <img src="https://img.youstarplanet.com/website/ruleOfSunshine/RuleOfSunshine.png" alt="">
        <div class="navigation" @click="CaseShow"><span>首页 > 阳光准则</span></div>
        <div class="content">
            <div>阳光准则</div>
            <p>
                星都时代信奉正直、进取、协作、创造的价值观，其中，为宣扬正直，我们制定了相关制度，采取了一系列措施，以预防、发现及阻止贪腐或任何违反公司正直价值观的行为。星都时代的全体员工均需严格遵守《星都时代阳光行为准则》（“阳光准则”），阳光准则明确的禁止各类型的贪腐、舞弊以及任何不符合法律法规的行为。<br>
                我们制定了《反舞弊举报制度》，清楚向全体员工传递星都时代对贪腐和舞弊行为零容忍的信息。我们鼓励所有员工及供应商/业务合作伙伴通过以下方式举报涉及星都时代员工的任何已经发生或可能发生的贪腐、舞弊及违规行为：<br>
                举报邮箱: jubao@youstarplanet.com<br>
                <!-- 微信公众号：阳光星都（yspsunshine）<br> -->
                举报电话: +86-0755-23144277<br>
                信件：中国广东省深圳市宝安区西乡街道资信达大厦518<br>
                我们鼓励您举报时留下您的姓名和联系方式，以便我们与您取得联系获取更多信息及全面的开展调查工作。同时，我们也会保证提供了有效举报信息的匿名举报同样得到认真的处理。我们已采取相关保密措施保证您的身份和您提供的信息不被公开。<br>
                我们不希望任何人杜撰、捏造事实，或恶意攻击、诽谤星都时代员工，如发现上述行为，星都时代将保留追究其责任的权利。我们严厉禁止以任何形式报复举报人、证人及调查人员，如存在，星都时代将严厉查处。<br><br>
                温馨提示：如果您想对星都时代相关业务进行咨询及投诉，建议您通过星都时代公司客服渠道进行反映，您的问题或咨询将得到更快的帮助。<br>
            </p>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return { }
    },
    methods: {
        CaseShow(){
            this.$router.push({path:`/Home`})
        }
    },
    async mounted() {
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.RuleOfSunshine{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .navigation{
        height: 48px;
        line-height: 48px;
        background-color: #F1F1F1;
        color: #8A8A8A;
        font-size: 14px;
        position: relative;
        top: -4px;
        span{
            display: block;
            width: 1270px;
            margin: 0 auto;
        }
    }
    .content{
        width: 1270px;
        margin: 68px auto 300px;
        padding: 0 80px;
        div{
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 40px;
        }
        &>p{
            font-size: 16px;
            line-height: 35px;
            text-align:justify; 
            text-justify:inter-ideograph;
        }
    }
}
</style>